.leftBar{
    width: 20%;
    box-shadow: 1px 0px 24px -7px rgba(16, 24, 40, 0.05), 2px 0px 4px -2px rgba(16, 24, 40, 0.05);
    display: inline-block;
    float: left;
}
.logo{
    padding: 40px 46px;
}
.leftTitle{
    font-size: 12px;
    color: #AEBEBC;
    font-family: 'Inter';
    padding-left: 0;
    position: absolute;
    left: 36px;
    top: -29px;
}
.subheadingThree .leftTitle, .subheadingTwo .leftTitle{
    top: -35px;
}
.leftbarContent li, .subheadingTwo ul li, .subheadingThree ul li{
    font-size: 14px;
    color: #6F8381;
    font-family: 'Inter';
    line-height: 27px;
    position: relative;
    list-style: none;
    padding: 13px 0;
    margin-left: 30px;
}
.subheadingThree, .subheadingTwo{
    position: relative;
    margin-top: 53px;
}
.subheadingThree li, .subheadingTwo li{
    top: -13px;
}
.leftBar ul li img{
    margin-top: 0;
    position: absolute;
    left: -32px;
    top: 16px;
}
.leftbarContent{
    position: relative;
}
.internalListone{
background: #E5FDFF;
color: #00ACB9;
padding: 12px 14px;
border-radius: 0px 8px 8px 0;
border-left: 2px solid #00ACB9;
padding: 8px 80px 8px 15px;
display: block;
}
.subOptions{
    width: 189px;
    margin-top: 10px;
}
.internalListtwo{
    color: #6F8381;
    padding: 12px 14px;
    border-radius: 0px 8px 8px 0;
    padding: 8px 80px 8px 15px;
    display: block;
}
.userLoginDetails{
    background: #F6F9F9;
    border: 1px solid #F1F4F3;
    border-radius: 12px;
    width: 229px;
    margin: 0 auto;
    padding: 16px 7px;
    text-align: center;
    position: relative;
}
.userLoginDetails li{
    list-style: none;
}
.userLoginDetails img {
    position: absolute;
    left: 44px;
    top: 17px;
}
.userName{
    font-size: 14px;
    color: #1E2524;
    font-weight: 600;
    padding-left: 19px;
}
.userAccess{
    font-size: 12px;
    font-weight: 400;
    color: #837B8E;
    padding-left: 12px;
    line-height: 25px;
}
.userId{
    font-size: 12px;
    font-weight: 500;
}
.userLoginDetails img.arrow{
    position: absolute;
    right: 23px;
    top: 42px;
    left: unset;
}