.rightBar{
    width: 73%;
    display: inline-block;
    padding: 40px 43px;
}
.pageTitle{
    font-size: 28px;
    color: #1E2524;
    font-weight: 600;
}
.active{
    font-size: 14px;
    color: #1E293B;
    font-weight: 600;

}
.active img{
    margin-left: 7px;
    margin-right: 5px;
    height: 10px;
}
.breadcrumbs li{
    list-style: none;
    display: inline-block;
}
.breadcrumbs{
    margin-top: 20px;
}
.searchBar{
width: 383px;
height: 48px;
border-radius: 12px;
background: #EFF5F5;
color: #667085;
padding-left: 44px;
border-color: #EFF5F5;
box-shadow: none;
}
.searchBar::placeholder{
    color: #667085;
    font-size: 14px;
    padding-left: 44px;
}
.manageDisplay{
    position: relative;
    margin-top: 55px;
    margin-bottom: 25px;
}
.searchIcon{
    position: absolute;
    top: 18px;
    left: 21px;
}
.searchBox{
    float: left;
    width: 30%;
}
.displayControls{
    display: inline-block;
    width: 65%;
    text-align: right;
}
.displayControls div{
    display: inline-block;
    margin-right: 13px;
    cursor: pointer;
}
.card{
    box-shadow: 0px 8px 28px -8px rgba(16, 24, 40, 0.06), 0px 0px 7px -2px rgba(16, 24, 40, 0.08);
    border-radius: 12px;
    padding: 14px;
    width: 100%;
}
.tableHeader li{
    list-style: none;
    width: 16%;
    display: inline-block;
}
.tableHeader{
    background: #F5F9F9;
    padding: 12px 0;
    border-radius: 12px;
    margin-bottom: 20px;
}
.name, .role{
    font-size: 14px;
    color: #000;
    font-weight: 500;
}
li.members {
    list-style: none;
    display: block;
}
.memberWrap>div{
    display: inline-block;
    width: 16%;
}
.tableHeader>li:last-child{
    padding-right: 0;
}
.memberWrap{
    height: 80px;
}
.name{
    padding-left: 15px;
}
.contactBtn{
    background: #EFF5F5;
    border-radius: 6px;
    font-size: 12px;
    color: #344054;
    padding: 11px, 16px, 11px, 16px;
    border-color: #EFF5F5;

}
button.statusBtn {
    border-radius: 6px;
    font-size: 12px;
    color: #344054;
    padding: 6px 16px;
    border-color: #EFF5F5;
    width: 100px;
    background: #EFF5F5;
}
.status{
    padding: 11px, 16px, 11px, 16px;
    border-color: #EFF5F5;
}
.viewBtn{
    background: #00ACB9;
    border-color: #00ACB9;
    border-radius: 6px;
}
.viewBtn{
    margin-right: 10px;
}
.leftNavi, .rightNavi, .rightNavi li{
    display: inline-block;
    list-style: none;
    
}
.rightNavi li{
    margin-right: 10px;
    cursor: pointer;
}
.rightNavi{
    float: right;
}
.firstPageLink{
    background: #EDFDFB;
    color: #00ACB9;
    border-radius: 8px;
    width: 26px;
    height: 26px;
    text-align: center;
    padding: 2px 6px;
    border: 1px solid;
    margin-right: 5px;
}
.lastPageLink{
    background: #fff;
    color: #344054;
    border-radius: 8px;
    width: 26px;
    height: 26px;
    text-align: center;
    padding: 2px 6px;
    border: 1px solid;
    margin-right: 5px;
}
.footerPager{
    margin-top: 30px;
}